<template>
  <v-app-bar
    v-scroll="onScroll"
    color="white"
    flat
  >
    <v-slide-x-transition>
      <v-img
        :src="require('@/assets/logo.png')"
        class="shrink"
        height="50"
      />
    </v-slide-x-transition>

    <v-spacer />

    <v-btn
      color="primary"
      outlined
      target="_blank"
      rel="noopener"
      href="https://taicca.tw"
    >
      {{ smAndDown ? 'TAICCA' :'Taiwan Creative Content Agency (TAICCA)' }}
    </v-btn>

    <!--<social-media />-->
  </v-app-bar>
</template>

<script>
  export default {
    name: 'CoreAppBar',

    components: {
      // SocialMedia: () => import('@/components/SocialMedia'),
    },

    data: () => ({
      showLogo: false,
      isScrolling: false,
    }),
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    methods: {
      onScroll () {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      },
    },
  }
</script>
